import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { UserStorageService } from "./user-storage.service";
import { IUserData, ANONYMOUS_USER } from "../model/IUser";
import { Token } from "../../oauth2/model/Token";

@Injectable({ providedIn: "root" })
export class UserService {
  protected authenticatedUserData: BehaviorSubject<
    IUserData
  > = new BehaviorSubject<IUserData>(ANONYMOUS_USER);

  constructor(
    private http: HttpClient,
    private userStore: UserStorageService
  ) { }

  get currentUserData(): Observable<IUserData> {
    return this.authenticatedUserData;
  }
 

  findCurrentUserData(id: number): Observable<IUserData> {
    const service = this;
    // TODO change with entrypoint on User entity in hr-ms by upn
    return this.http.get<IUserData>(`/hr-ms/api/v1/human-resources/${id}`).pipe(
      map((userData: IUserData) => {
        this.userStore.saveUserDate(userData);
        service.authenticatedUserData.next(userData);
        return userData;
      })
    );
  }

  findCurrentLoggedUser(id: bigint): Observable<IUserData>{
    const service = this;
    // TODO change with entrypoint on User entity in hr-ms by upn
    return this.http.get<IUserData>(` /talenteo-oauth2/api/v1/users/${id}`).pipe(
      map((userData: IUserData) => {
        this.userStore.saveUserDate(userData);
        service.authenticatedUserData.next(userData);
        return userData;
      })
    );
  }

  updateUser(id: number, map: any) {
    return this.http.put("/talenteo-oauth2/api/v1/users/" + id, map);
  }

  sendEmailToUser(email: String, language: String, accessToken: Token) {
    const requestParam = `?language=${language}&email=${email}`
    return this.http.post(`/talenteo-oauth2/api/v1/users/mail${requestParam}`,
      {
        headers: {
          Authorization: "Bearer" + accessToken.access_token
        },
      }
    );
  }

  resetPassword(id: String, password: any, accessToken: Token) {
    return this.http.post("/talenteo-oauth2/api/v1/users/reset-password/" + id + "?userPassword=" + password, {},
      {
        headers: {
          Authorization: "Bearer" + accessToken.access_token
        },
      }
    );
  }

  logout(param) {
    this.authenticatedUserData.next(ANONYMOUS_USER);
  }
}
