export const HUMAN_RESOURCE_PROFILES = {
  SUPER_ADMIN: "Super_Admin",
  COMPANY_ADMIN: "Company_Admin",
  BUSINESS_MANAGER: "Manager",
  CONSULTANT: "Consultant",
};

export const HUMAN_RESOURCE_ROLES = {
  SUPER_ADMIN: "SUPER_ADMIN",
  COMPANY_ADMIN: "COMPANY_ADMIN",
  BUSINESS_MANAGER: "BUSINESS_MANAGER",
  CONSULTANT: "CONSULTANT",
  VISITOR: "VISITOR",
  HUMAN_RESOURCE: "HUMAN_RESOURCE"
};

export const LANGUAGES = {
  FR: "fr",
  EN: "en",
};

export const FRENCH_LABELED_DATA_TABLE = {
  processing: "Traitement en cours...",
  search: "Rechercher&nbsp;:",
  lengthMenu: "Afficher _MENU_ éléments",
  info: "Affichage de l'élément _START_ à _END_ sur _TOTAL_ éléments",
  infoEmpty: "Affichage de l'élément 0 à 0 sur 0 éléments",
  infoFiltered: "(filtré de _MAX_ éléments au total)",
  infoPostFix: "",
  loadingRecords: "Chargement en cours...",
  zeroRecords: "Aucun élément à afficher",
  emptyTable: "Aucune donnée disponible dans le tableau",
  paginate: {
    first: "Premier",
    previous: "Précédent",
    next: "Suivant",
    last: "Dernier",
  },
  aria: {
    sortAscending: ": activer pour trier la colonne par ordre croissant",
    sortDescending: ": activer pour trier la colonne par ordre décroissant",
  },
};

export const ROUTING_PATH = {
  login: 'login',
  logout: 'logout',
  dashboard: 'dashboard',
  humanResources: 'human-resources',
  humanResourceDetails: 'human-resources/',
  assessmentDetails: 'assessment/details/',
  error403: 'error/403',
  error404: 'error/404',
  changePwd: 'reset-password'

}
